import { useState } from 'react'
import './Sidecontroller.scss'
import {
  MdOutlineMapsHomeWork,
  MdPermContactCalendar,
  MdOutlineWork,
} from 'react-icons/md'
import { SiPowerbi } from 'react-icons/si'
import { BsInfoSquare } from 'react-icons/bs'

const Sidecontroller = () => {
  const [isSmoothMenuopen, setisSmoothMenuopen] = useState(false)
  const showMenuHandler = isSmoothMenuopen ? 'smoothmenushow' : ''
  // onClick={setisSmoothMenuopen(true)}
  return (
    <>
      <div className={`smooth__controller ${showMenuHandler}`}>
        <div
          className={`hamburger-menu ${showMenuHandler}`}
          onClick={() => setisSmoothMenuopen(!isSmoothMenuopen)}
        >
          <div className="bar"></div>
        </div>
      </div>

      <div className={`smoothmenu ${showMenuHandler}`}>
        <div className="sidebar-links">
          <nav className="sidebar-links-nav">
            <ul className="sidebar-links-ul">
              <li>
                <a
                  href="#home"
                  onClick={() => setisSmoothMenuopen(!isSmoothMenuopen)}
                >
                  <span className="tooltip">Home</span>
                  <span className="side_nav-icon">
                    <MdOutlineMapsHomeWork />
                  </span>
                </a>
              </li>
              <li>
                <a
                  href="#services"
                  onClick={() => setisSmoothMenuopen(!isSmoothMenuopen)}
                >
                  <span className="tooltip">Services</span>
                  <span className="side_nav-icon">
                    <BsInfoSquare />
                  </span>
                </a>
              </li>
              <li>
                <a
                  href="#work"
                  onClick={() => setisSmoothMenuopen(!isSmoothMenuopen)}
                >
                  <span className="tooltip">Porfolio</span>
                  <span className="side_nav-icon">
                    <MdOutlineWork />
                  </span>
                </a>
              </li>
              <li>
                <a
                  href="#skills"
                  onClick={() => setisSmoothMenuopen(!isSmoothMenuopen)}
                >
                  <span className="tooltip">Skill</span>
                  <span className="side_nav-icon">
                    <SiPowerbi />
                  </span>
                </a>
              </li>
              {/* <li>
                <a
                  href="#testimonial"
                  onClick={() => setisSmoothMenuopen(!isSmoothMenuopen)}
                >
                  <span className="tooltip">Testimonial</span>
                  <span className="side_nav-icon">
                    <SiSpeedtest />
                  </span>
                </a>
              </li> */}
              <li>
                <a
                  href="#contact"
                  onClick={() => setisSmoothMenuopen(!isSmoothMenuopen)}
                >
                  <span className="tooltip">Contact</span>
                  <span className="side_nav-icon">
                    <MdPermContactCalendar />
                  </span>
                </a>
              </li>
              {/* <div className="User__settings">Night/Day</div> */}
            </ul>
          </nav>
        </div>
      </div>
    </>
  )
}

export default Sidecontroller
