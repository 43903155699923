import React, { useState, useEffect } from 'react'
import './Skills.scss'
import { AppWrapp, MotionWrap } from '../../wrapper'
import { motion } from 'framer-motion'
import { client, urlFor } from '../../client'
// import Tippy from '@tippy.js/react'
// import './tippy.js/dist/tippy.css'

const Skills = () => {
  const [skillsData, setskillsData] = useState([])
  const [experienceData, setexperienceData] = useState([])

  useEffect(() => {
    const experienceQuery = '*[_type == "experiences"]'
    const skillsQuery = '*[_type == "skills"]'

    client.fetch(skillsQuery).then((data) => {
      setskillsData(data)
    })

    client.fetch(experienceQuery).then((data) => {
      setexperienceData(data)
    })
  }, [])
  return (
    <>
      <h2 className="head-text">Tools & Experience</h2>
      <div className="app__skills-container">
        <motion.div className="app__skills-list">
          {skillsData.map((skill, index) => (
            <motion.div
              whileInView={{ opacity: [0, 1] }}
              transition={{ duration: 0.5 }}
              className="app__skills-item app__flex"
              key={index}
            >
              <div
                className="app__flex skillsBG"
                // style={{ backgroundColor: skill.bgColor }}
              >
                <img src={urlFor(skill.icon)} alt={skill.name} />
              </div>
              <p className="p-text">{skill.name}</p>
            </motion.div>
          ))}
        </motion.div>

        <motion.div className="app__skills-exp">
          {experienceData?.map((exp, index) => (
            <motion.div className="app__skills-exp-item" key={`year-${index}`}>
                <span className="app__skill-year">{exp.year}</span>
              <motion.div className="app__skills-exp-works">
                {experienceData[index].works.map((work, index) => (
                  <motion.div
                    whileInView={{ opacity: [0, 1] }}
                    transition={{ duration: 0.5 }}
                    className="app__skills-exp-work"
                    data-tip
                    data-for={work.name}
                    key={`company-${index}`}
                  >
                    <h4 className="bold-text">{work.name}</h4>
                    <p className="p-text">{work.company}</p>
                  </motion.div>
                ))}
                <div className="skillball"></div>
              </motion.div>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </>
  )

}

export default AppWrapp(
  MotionWrap(Skills, 'app__skills'),
  'skills',
  'skill-section',
)
