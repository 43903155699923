import React, { useState, useEffect } from 'react'
import './portfolio.scss'
import { motion } from 'framer-motion'
import { AppWrapp, MotionWrap } from '../../wrapper'
import { client, urlFor } from '../../client'
import { FiGithub } from 'react-icons/fi'
import { HiOutlineExternalLink } from 'react-icons/hi'

const Portfolio = () => {
  const [activeFilter, setActiveFilter] = useState('ALL')
  const [animateCard, setAnimateCard] = useState({ y: 0, opacity: 1 })
  const [Works, setWorks] = useState([])
  const [FilterWork, setFilterWork] = useState([])

  const handleWorkFilter = (item) => {
    setActiveFilter(item)
    setAnimateCard([{ y: 100, opacity: 0 }])

    setTimeout(() => {
      setAnimateCard([{ y: 0, opacity: 1 }])
    }, 500)

    if (item === 'ALL') {
      setFilterWork(Works)
    } else {
      setFilterWork(Works.filter((work) => work.tags.includes(item)))
    }
  }

  useEffect(() => {
    const query = '*[_type=="works"]'
    client.fetch(query).then((data) => {
      setWorks(data)
      setFilterWork(data)
    })
  }, [])
  return (
    <>
      <h2 className="head-text">Portfolio</h2>
      <div className="app__work-filter">
        {['UI/UX', 'HTML & CSS', 'NODE JS', 'REACT JS', 'ALL'].map(
          (item, index) => (
            <div
              key={index}
              onClick={() => handleWorkFilter(item)}
              className={`app__work-filter-item app__flex p-text ${
                activeFilter === item ? 'item-active' : ''
              }`}
            >
              {item}
            </div>
          ),
        )}
      </div>
      <motion.div
        animate={animateCard}
        transition={{ duration: 0.5, delayChildren: 0.5 }}
        className="app__work-portfolio"
      >
        {FilterWork.map((work, index) => (
          <div className="app__work-wrapper" key={index}>
            <div className="app__work-title">
              <a href={work.projectLink} target="_blank" rel="noreferrer">
                <span className="">{work.title}</span>
              </a>
            </div>

            <div className="app__work-item app__flex">
              <div className="app__work-img app__flex">
                <img src={urlFor(work.imgUrl)} alt={work.name} />
              </div>
              <div className="app__work-content app__flex">
                <div className="app__work-actions">
                  <a href={work.projectLink} target="_blank" rel="noreferrer">
                    <motion.div
                      whileInView={{ scale: [0, 1] }}
                      whileHover={{ scale: [1, 0.9] }}
                      transition={{ duration: 0.25 }}
                      className="app__flex"
                    >
                      <HiOutlineExternalLink />
                    </motion.div>
                  </a>

                  <a href={work.codeLink} target="_blank" rel="noreferrer">
                    <motion.div
                      whileInView={{ scale: [0, 1] }}
                      whileHover={{ scale: [1, 0.9] }}
                      transition={{ duration: 0.25 }}
                      className="app__flex"
                    >
                      <FiGithub />
                    </motion.div>
                  </a>
                </div>
                <div className="app__work-desc">
                  <p className="p-text" style={{ marginTop: 10 }}>
                    {work.description}
                  </p>
                </div>

                <div className="app__work-tech app__flex">
                  {work.technologies.map((tech, index) => (
                    <span key={index}>{tech}</span>
                  ))}
                </div>
              </div>
              <div className="app__work-tag app__flex">
                <p>{work.tags[0]}</p>
              </div>
            </div>
          </div>
        ))}
      </motion.div>
    </>
  )

  const FrontendBootcamp = () => {
    
  }
}

export default AppWrapp(
  MotionWrap(Portfolio, 'app__works'),
  'work',
  'portfolio-section',
)
