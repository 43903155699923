import email from '../assets/email.png';
import mobile from '../assets/mobile.png';
import api from '../assets/api.png';
import cpp from '../assets/cpp.png';
import css from '../assets/css.png';
import figma from '../assets/figma.png';
import flutter from '../assets/flutter.png';
import git from '../assets/git.png';
import graphql from '../assets/graphql.png';
import html from '../assets/html.png';
import javascript from '../assets/javascript.png';
import mu5 from '../assets/mu5.png';
import node from '../assets/node.png';
import python from '../assets/python.png';
import react from '../assets/react.png';
import redux from '../assets/redux.png';
import sass from '../assets/sass.png';
import typescript from '../assets/typescript.png';
import vue from '../assets/vue.png';

import about01 from '../assets/about01.png';
import about02 from '../assets/about02.png';
import about03 from '../assets/about03.png';
import about04 from '../assets/about04.png';

// import profile from '../assets/blob.png';
import profile from '../assets/intro_img.png';
import circle from '../assets/circle.svg';
import logo from '../assets/logo.png';

import adidas from '../assets/adidas.png';
import amazon from '../assets/amazon.png';
import asus from '../assets/asus.png';
import bolt from '../assets/bolt.png';
import nb from '../assets/nb.png';
import skype from '../assets/skype.png';
import spotify from '../assets/spotify.png';

import icircle from '../assets/icircle.png';
import ihcircle from '../assets/ihcircle.png';
import iwibble from '../assets/iwible.png';
import itriangle from '../assets/itriangle.png';
import idotted from '../assets/idotted.png';
import isound from '../assets/isound.png';
import iamoeba from '../assets/iamoeba.png';
import ibox from '../assets/ibox.png';
import iboxball from '../assets/iboxball.png';
import ibgroup from '../assets/ibGroup.png';
import ihedge from '../assets/ihedge.png';
import isnake from '../assets/isnake.png';
import resume from '../assets/israel_akinwumi_resume.pdf';



export default {
    email,
    mobile,
    api,
    cpp,
    css,
    figma,
    flutter,
    git,
    graphql,
    html,
    javascript,
    mu5,
    node,
    python,
    react,
    redux,
    sass,
    typescript,
    vue,

    about01,
    about02,
    about03,
    about04,

    profile,
    circle,
    logo,

    adidas,
    amazon,
    asus,
    bolt,
    nb,
    skype,
    spotify,
    icircle,
    ihcircle,
    iwibble,
    itriangle,
    idotted,
    isound,
    iamoeba,
    ibox,
    iboxball,
    ibgroup,
    ihedge,
    isnake,
    resume
};