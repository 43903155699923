import React from 'react'
import './App.scss'
import { Header, Footer, Portfolio, Services, Skills, Contact } from "./container";
import ProgressBar from 'react-progressbar-on-scroll'


const App = () => {
  return (
    <>
      <ProgressBar
        color="#007ced"
        gradient={true}
        gradientColor="#008BF5"
        height={5}
      />
      <header>

        <Header />
      </header>
      <main>

        <Services />
        <Portfolio />
        <Skills />
        {/* <Testimonial /> */}
        <Contact />
      </main>
      <footer>
        <Footer />

      </footer>
    </>
  )
}

export default App