import React, { useState } from 'react'
import './Contact.scss'
import { AppWrapp, MotionWrap } from '../../wrapper'
import { client } from '../../client'
import { FaLinkedinIn } from 'react-icons/fa'
import { SiGmail } from 'react-icons/si'
import { CgPhone } from 'react-icons/cg'
import { AiOutlineFileDone } from 'react-icons/ai'
import { Images } from '../../constants'

const Contact = () => {
  const [formData, setFormData] = useState({ name: '', email: '', message: '' })
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  const [loading, setLoading] = useState(false)

  const { name, email, message } = formData

  const handleChangeInput = (e) => {
    const { name, value } = e.target

    setFormData({ ...formData, [name]: value })
  }
  const handleSubmit = () => {
    setLoading(true)
    const contact = {
      _type: 'contact',
      name: name,
      email: email,
      message: message,
    }
    client.create(contact).then(() => {
      setLoading(false)
      setIsFormSubmitted(true)
    })
  }
  return (
    <>
      <h2 className="head-text">Connect !!</h2>
      {/* <p>
        Hi there, I am super excited to talk to you about your next big app, let
        me turn your amazing ideas to code and deliver highly responsive and eye
        catching design that suits just what you want them for.
      </p> */}
      <div className="contact-container">
        {!isFormSubmitted ? (
          <div className="app__flex app__contact-form ">
            <h3 className="title">Leave a message</h3>

            <input
              type="text"
              name="name"
              className="form-input"
              placeholder="Enter your name"
              value={name}
              onChange={handleChangeInput}
            />
            <input
              type="text"
              name="email"
              className="form-input"
              placeholder="Enter your email"
              value={email}
              onChange={handleChangeInput}
            />
            <textarea
              name="message"
              value={message}
              placeholder="Your message..."
              onChange={handleChangeInput}
              className="form-input"
            />
            <button type="button" className="form-btn" onClick={handleSubmit}>
              {loading ? 'Sending ...' : 'Send Message'}
            </button>
          </div>
        ) : (
          <div className="contact-response">
            <h3 className="title">Response received</h3>
            <div className="contact-resend">
              <AiOutlineFileDone />
              <button
                className="form-btn"
                onClick={() => setIsFormSubmitted(false)}
              >
                Send some other message
              </button>
            </div>
          </div>
        )}
        <div className="app__contact-card">
          <div className="contact__card-info">
            <h3 className="title">Fast lane</h3>
            <a
              href="http://www.linkedin.com/in/akinwumidi"
              className="contact__card-link"
              target="_blank"
              rel="noreferrer"
            >
              <span className="contact__card-iconHolder">
                <FaLinkedinIn />
              </span>
              <span>linkedin.com/in/akinwumidi</span>
            </a>

            <a
              href="tel:+2348158316730"
              className="contact__card-link"
              target="_blank"
              rel="noreferrer"
            >
              <span className="contact__card-iconHolder">
                <CgPhone />
              </span>
              <span>+234 (815) 831-6730</span>
            </a>

            <a
              href="mailto:israelakin14@gmail.com"
              className="contact__card-link"
              target="_blank"
              rel="noreferrer"
            >
              <span className="contact__card-iconHolder">
                <SiGmail />
              </span>
              <span>israelakin14@gmail.com</span>
            </a>
          </div>
          <div className="app_header-overlay">
            <img
              src={Images.ibgroup}
              className="bgshapes ibgroup"
              alt="overlay-particles"
            />
            <img
              src={Images.isound}
              className="bgshapes isound"
              alt="overlay-particles"
            />
            <img
              src={Images.iboxball}
              className="bgshapes iboxball"
              alt="overlay-particles"
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default AppWrapp(
  MotionWrap(Contact, 'contact'),
  'contact',
  'contact-section',
)
