import React from 'react'
// import { AppWrapp, MotionWrap } from '../../wrapper'
// import { client } from '../../client'
// import { Images } from '../../constants'
import './footer.scss'
import { RiArrowRightLine } from 'react-icons/ri'
import { FaLinkedinIn } from 'react-icons/fa'
import { BsTwitter } from 'react-icons/bs'
import { SiGmail } from 'react-icons/si'
import { IconContext } from 'react-icons'

const Footer = () => {
  // const [formData, setFormData] = useState({ name: '', email: '', message: '' })
  // const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  // const [loading, setLoading] = useState(false)

  // const { name, email, message } = formData

  // const handleChangeInput = (e) => {
  //   const { name, value } = e.target

  //   setFormData({ ...formData, [name]: value })
  // }
  // const handleSubmit = () => {
  //   setLoading(true)
  //   const contact = {
  //     _type: 'contact',
  //     name: name,
  //     email: email,
  //     message: message,
  //   }
  //   client.create(contact).then(() => {
  //     setLoading(false)
  //     setIsFormSubmitted(true)
  //   })
  // }

  return (
    <>
      <section className="footer-signature">
        <div className="footerbox">
          <div className="footerbox__signature-disclaimer">
            <p>
              &copy; 2022 Built and Designed by
              <span>
                <a
                  href="http://www.linkedin.com/in/akinwumidi"
                  target="_blank"
                  rel="noreferrer"
                >
                  Israel Akinwumi
                </a>
              </span>
            </p>
          </div>
          <IconContext.Provider value={{ className: 'footer__iconBox-icon' }}>
            <div className="footer__iconBox">
              <span className="footer-icon_holder">
                <a
                  href="mailto:israelakin14@gmail.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <SiGmail />
                </a>
              </span>
              <span className="footer-icon_holder">
                <a
                  href="https://twitter.com/akinwumidi"
                  target="_blank"
                  rel="noreferrer"
                >
                  <BsTwitter />
                </a>
              </span>
              <span className="footer-icon_holder">
                <a
                  href="http://www.linkedin.com/in/akinwumidi"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaLinkedinIn />
                </a>
              </span>
            </div>
          </IconContext.Provider>
          <div className="footerbox__signature-contact">
            <a
              href="http://www.linkedin.com/in/akinwumidi"
              target="_blank"
              rel="noreferrer"
              className="footer-btn-a"
            >
              <button className="footer-btn">
                Contact Developer
                <IconContext.Provider value={{ className: 'icon-dev' }}>
                  <RiArrowRightLine />
                </IconContext.Provider>
              </button>
            </a>
          </div>
        </div>
      </section>
    </>
  )
}

export default Footer
