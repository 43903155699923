import './SocialMedia.scss'
import React from 'react'
import { BsTwitter } from 'react-icons/bs'
import { FiGithub } from 'react-icons/fi'
import { FaLinkedinIn } from 'react-icons/fa'

const SocialMedia = () => {
  return (
    <div className="app__social">
      <div>
        <a
          href="https://github.com/akinwumidi"
          target="_blank"
          rel="noreferrer"
        >
          <FiGithub />
        </a>
      </div>
      <div>
        <a
          href="https://twitter.com/akinwumidi"
          target="_blank"
          rel="noreferrer"
        >
          <BsTwitter />
        </a>
      </div>
      <div>
        <a
          href="https://www.linkedin.com/in/akinwumidi/"
          target="_blank"
          rel="noreferrer"
        >
          <FaLinkedinIn />
        </a>
      </div>
    </div>
  )
}

export default SocialMedia
