import React, { useState, useEffect } from 'react'
import './services.scss'
import { motion } from 'framer-motion'
// import { Images } from '../../constants'
import { client, urlFor } from '../../client'
import { AppWrapp, MotionWrap } from '../../wrapper'

const Services = () => {
  const [aboutsData, setAboutsData] = useState([])
  useEffect(() => {
    const query = '*[_type == "services"]'

    client.fetch(query).then((data) => setAboutsData(data))
  }, [])

  return (
    <div className="app__about">
      <h2 className="head-text">Services</h2>
      <div className="app__profiles">
        <div className="services-intro">
          Let's talk <br />
          about your project.
          <a href="#contact">
            <button className="header__btn"> connect</button>
          </a>
        </div>
        {aboutsData.map((service, index) => (
          <motion.div
            key={`index-${service.title}`}
            whileInView={{ opacity: [0, 1] }}
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.5, type: 'tween' }}
            className="app__profile-item"
          >
            <div className="serviceholder-overlay"></div>
            <div className="services-Imgholder">
              <img src={urlFor(service.imgUrl)} alt={service.title} />
            </div>
            <div className="services-details">
              <h2 className="bold-text" style={{ marginTop: 8 }}>
                {service.title}
              </h2>
              <p className="p-text" style={{ marginTop: 6 }}>
                {service.description}
              </p>
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  )
}

export default AppWrapp(
  MotionWrap(Services, 'app__services'),
  'services',
  'services-section',
)
